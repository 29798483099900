
/* eslint-disable no-param-reassign */
import { ref, defineComponent, PropType, computed, watch, inject, onMounted } from '@vue/runtime-core';
import { defineSelector, ActionsGroup } from './components';
import { CreatDtoModel, DtoModel, DtoProps, LocalDtoProps } from './types';
import SelectDto from './SelectDto.vue';
import {
  useValidate,
  usePropList,
  isComplex,
  isPrimitive,
  getList,
  toDtoPropsList,
  toPlainObject,
  BOOLEAN_OPTIONS,
  DATA_TYPES,
  isArrayItem,
} from './props-list';
import StringSettingDialog from '../api-params/settings/String.vue';
import FloatSettingDialog from '../api-params/settings/Float.vue';
import IntSettingDialog from '../api-params/settings/Int.vue';
import DateSettingDialog from '../api-params/settings/Date.vue';
import BooleanSettingDialog from '../api-params/settings/Boolean.vue';
export default defineComponent({
  name: 'PropertiesList',
  props: {
    list: {
      type: Array as PropType<DtoProps[]>,
      default: () => [],
    },
    dto: {
      type: Object as PropType<DtoModel | CreatDtoModel>,
      default: () => ({}),
    },
  },
  components: {
    RequriedSelector: defineSelector<number>(),
    DataTypeSelector: defineSelector<string>(),
    ActionsGroup,
    SelectDto,
    StringSettingDialog,
    FloatSettingDialog,
    IntSettingDialog,
    DateSettingDialog,
    BooleanSettingDialog,
  },
  setup(props) {
    const localList = ref<LocalDtoProps[]>(getList(props.list)); // note: computed locallist table 存在不重绘问题
    const editStatusQuery = ref(0 as any);
    watch(
      () => props.list,
      () => {
        console.log('listchange');

        localList.value = getList(props.list);
      },
    );
    onMounted(() => {
      const editStatus: any = inject('editStatus');
      if (editStatus.value) {
        editStatusQuery.value = parseInt(editStatus.value);
      } else {
        editStatusQuery.value = 0;
      }
    });
    const {
      handleTypeChange,
      handleRemove,
      handleAdd,
      handleImport,
      getActions,
      handleConfigChange,
      importType,
      selectDtoRef,
      settingRefs,
    } = usePropList(localList, props.dto);

    // --------------------------------

    const dtoPropsList = computed(() => toDtoPropsList(localList.value, props.dto));

    const plainDto = computed(() => toPlainObject(localList.value, {}));

    const example = computed(() => JSON.stringify(plainDto.value, null, 4));

    // ----------------------validate
    const { validateProp, validate } = useValidate(localList);
    /**
     * 外部调用方法
     */
    const getData = () => {
      if (validate()) {
        return dtoPropsList.value;
      }
    };
    return {
      localList,
      getData,
      isComplex,
      isPrimitive,
      handleTypeChange,
      handleAdd,
      handleRemove,
      getActions,
      handleImport,
      validateProp,
      validate,
      handleConfigChange,
      BOOLEAN_OPTIONS,
      DATA_TYPES,
      importType,
      selectDtoRef,
      dtoPropsList,
      example,
      isArrayItem,
      ...settingRefs,
      editStatusQuery,
    };
  },
});
