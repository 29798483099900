
import { defineComponent, reactive } from 'vue';
import Base from './Base';
const { handleSubmit, form, ...baseApi } = Base();
export default defineComponent({
  name: 'DateSettingDialog',
  setup(props, { emit }) {
    const checkFormat = (rule: any, value: any, callback: any) => {
      if (form.type === 2) {
        if (!value) {
          callback(new Error('请输入格式限制'));
          return;
        }
        if (!/[ymdhHmsSEDFwWakK]+/.test(value)) {
          callback(new Error('格式错误，请参考提示'));
        }
      }
      callback();
    };
    const formRules = reactive({
      type: [
        {
          required: true,
          message: '请选择类型',
          trigger: 'blur',
        },
      ],
      format: [{ validator: checkFormat, trigger: 'blur' }],
    });
    return {
      formRules,
      form: reactive({
        ...form,
        type: 1,
      }),
      ...baseApi,
      handleSubmit: () => {
        handleSubmit(emit);
      },
    };
  },
});
