
import { genId } from '@/utils/util';
import { ref } from '@vue/reactivity';
import { computed, defineComponent, watch, PropType } from '@vue/runtime-core';
import { ElMessage, ElTree } from 'element-plus';
import { FilterNodeMethodFunction } from 'element-plus/lib/el-tree/src/tree.type';
import { useForceUpdate } from '../business-service/components/useVerionInput';
import { useDialog, useDtoList, EMPTY_DTO, getTreeList, filterTreeList, toDtoList } from './dto-list';
import { DtoPropsOfSelector, ImportType } from './types';

export default defineComponent({
  name: 'SelectDto',
  emits: ['on-confirm'],
  props: {
    importType: {
      type: Number as PropType<ImportType>,
      default: ImportType.Clone,
    },
  },
  setup(props, ctx) {
    const defaultProps = {
      children: 'children',
      label: 'name',
    };
    const treeRef = ref<InstanceType<typeof ElTree>>();

    const filterText = ref<string>('');
    const { dtoList } = useDtoList();

    const selectedId = ref<string>();

    watch(filterText, (text) => treeRef.value?.filter(text));

    const filterNode: FilterNodeMethodFunction = (value, data) => {
      if (!value) return true;
      return data.name?.indexOf(value) !== -1;
    };

    const treeData = computed(() =>
      dtoList.value?.map((e) => {
        const { list, ...rest } = e;
        return {
          ...rest,
          _id: genId(),
          children: getTreeList(list),
        };
      }),
    );
    const isClone = computed(() => props.importType === ImportType.Clone);

    const getCheckedNodes = () => {
      if (!isClone.value) {
        // import
        const data = dtoList.value?.find((item) => item.uniqueId === selectedId.value);
        if (!data) {
          ElMessage.error('请至少选择一项');
          return;
        }
        return {
          ...data,
        };
      }
      // clone
      const checkedKeys = treeRef.value?.getCheckedKeys(true) ?? [];
      const checkedDtos =
        treeData.value
          ?.map((e) => {
            const { children, ...rest } = e;
            return {
              ...rest,
              children: toDtoList(
                filterTreeList(children, (e: DtoPropsOfSelector) =>
                  checkedKeys.includes(e._id),
                ) as DtoPropsOfSelector[],
              ),
            };
          })
          .filter((e) => e.children.length !== 0) ?? [];
      if (checkedDtos.length === 1) {
        // 只有一个根节点
        const { children, ...rest } = checkedDtos[0];
        return {
          ...rest,
          list: children,
        };
      }

      // 跨dto选择,合并为一个新dto
      return {
        ...EMPTY_DTO,
        list: checkedDtos?.map((e) => e.children).flat(),
      };
    };
    const { renderKey, forceUpdate } = useForceUpdate();
    const { openDialog, closeDialog, showDialog } = useDialog({
      onOpen: () => {
        filterText.value = '';
        forceUpdate();
      },
    });
    const onConfirm = () => {
      ctx.emit('on-confirm', getCheckedNodes());
      closeDialog();
    };

    return {
      treeData,
      dtoList,
      defaultProps,
      filterText,
      treeRef,
      showDialog,
      isClone,
      selectedId,
      renderKey,
      forceUpdate,
      filterNode,
      getCheckedNodes,
      openDialog,
      closeDialog,
      onConfirm,
    };
  },
});
