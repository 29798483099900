
import { ref } from '@vue/reactivity';
import { useEditDtoDialog, useDtoList, EMPTY_DTO } from './dto-list';
import { defineComponent, inject, onActivated, onBeforeMount } from '@vue/runtime-core';
import EditDtoModel from './EditDtoModel.vue';
import { useRoute } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getShowBool } from '@/utils/permission-show-module';
import { useForceUpdate } from '../business-service/components/useVerionInput';
import { DtoModel, EditMode, CreatDtoModel, DtoSource } from './types';
import { curServiceInfo } from '../business-service/useAddProxy';
export default defineComponent({
  name: 'DtoListDialog',
  components: {
    EditDtoModel,
  },
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
    isRefrenceService: {
      type: Boolean,
      default: false,
    },
    editStatus: {
      type: Number,
      default: 0,
    },
  },
  emits: ['on-confirm', 'back'],
  setup(props, { emit }) {
    const { fetchDtoList, dtoList, loading, removeDto } = useDtoList();

    const route = useRoute();

    const { serviceId, apiId, id } = route.params;

    const serviceInfo = inject(curServiceInfo);
    const currentServiceId = (serviceId ?? id) as string;
    const confirmLoading = ref<boolean>(false);

    onBeforeMount(() => {
      fetchDtoList(currentServiceId);
    });
    const {
      currentDto,
      showDialog: showEditDto,
      initEdit,
      closeDialog: closeEditDto,
      syncDtoData,
      setDtoModel,
      dialogTitle,
    } = useEditDtoDialog();

    const selectedId = ref<any>(); // hold ids of mutilple select

    const editDtoModelRef = ref<InstanceType<typeof EditDtoModel>>();

    const { renderKey, forceUpdate } = useForceUpdate();
    const editDtoModel = (data?: DtoModel) => {
      // eslint-disable-next-line no-unused-expressions
      editDtoModelRef.value?.dtoForm?.resetFields();
      if (data) {
        // update
        initEdit(EditMode.Update, data);
      } else {
        // create
        const createData: CreatDtoModel = {
          ...EMPTY_DTO,
          serviceId: Number(currentServiceId),
          apiId: Number(apiId),
          serviceName: serviceInfo?.value.name ?? '',
        };
        initEdit(EditMode.Create, createData);
      }
      forceUpdate();
    };

    const onConfirmEdit = async () => {
      try {
        confirmLoading.value = true;
        const dtoData = await editDtoModelRef.value?.getData();
        if (dtoData) {
          setDtoModel(dtoData);
          await syncDtoData();
          closeEditDto();
          // 刷新列表
          fetchDtoList(currentServiceId);
          confirmLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        confirmLoading.value = false;
      }
    };
    const getSelectedData = () => {
      const selected = selectedId.value;
      const row = dtoList.value?.find((item) => item.uniqueId === selected);
      if (!row) {
        ElMessage.error('请至少选择一项');
        return;
      }
      return row;
    };
    const resetList = () => {
      selectedId.value = '';
      // eslint-disable-next-line no-unused-expressions
      editDtoModelRef.value?.dtoForm?.resetFields();
      confirmLoading.value = false;
    };
    const removeDtoModel = async (row: DtoModel) => {
      ElMessageBox.confirm(`确认删除 DTO ${row.name}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        removeDto({
          serviceId: currentServiceId,
          uniqueId: row.uniqueId,
        });
      });
    };
    const handleClose = () => {
      confirmLoading.value = false;
      emit('back');
    };
    // -----
    const showAction = (row: DtoModel) => row.source === DtoSource.Other;
    onActivated(() => {
      fetchDtoList(currentServiceId);
    });
    return {
      dtoList,
      currentDto,
      showEditDto,
      editDtoModelRef,
      loading,
      selectedId,
      confirmLoading,
      dialogTitle,
      renderKey,
      onConfirmEdit,
      closeEditDto,
      editDtoModel,
      fetchDtoList,
      getSelectedData,
      resetList,
      removeDtoModel,
      handleClose,
      getShowBool,
      showAction,
    };
  },
});
