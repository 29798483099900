export const keywords = [
  'abstract',
  'class',
  'extends',
  'implements',
  'null',
  'strictfp',
  'true',
  'assert',
  'const',
  'false',
  'import',
  'package',
  'super',
  'try',
  'boolean',
  'continue',
  'final',
  'instanceof',
  'private',
  'switch',
  'void',
  'break',
  'default',
  'finally',
  'int',
  'protected',
  'synchronized',
  'volatile',
  'byte',
  'do',
  'float',
  'interface',
  'public',
  'this',
  'while',
  'case',
  'double',
  'for',
  'long',
  'return',
  'throw',
  'catch',
  'else',
  'goto',
  'native',
  'short',
  'throws',
  'char',
  'enum',
  'if',
  'new',
  'static',
  'transient',
];

/**
 * 关键字检测
 * @param value
 * @returns
 */
export const isKeyword = (value: string): boolean => {
  const name = value.toLocaleLowerCase();
  return keywords.includes(name);
};
