
import { defineComponent, reactive } from 'vue';
import Base from './Base';
const { handleSubmit, form, ...baseApi } = Base();
export default defineComponent({
  name: 'StringSettingDialog',
  setup(props, { emit }) {
    const checkMinlength = (rule: any, value: any, callback: any) => {
      const minLength = value !== undefined ? Number(value) : undefined;
      const maxLength = form.maxLength !== undefined ? Number(form.maxLength) : undefined;
      if (minLength !== undefined && maxLength !== undefined && minLength > maxLength) {
        callback(new Error('最小值不能大于最大值'));
      } else {
        callback();
      }
    };
    const checkMaxlength = (rule: any, value: any, callback: any) => {
      const maxLength = value !== undefined ? Number(value) : undefined;
      const minLength = form.maxLength !== undefined ? Number(form.maxLength) : undefined;
      if (minLength !== undefined && maxLength !== undefined && minLength > maxLength) {
        callback(new Error('最大值不能小于最小值'));
      } else {
        callback();
      }
    };
    const formRules = reactive({
      defaultValue: [],
      minLength: [{ validator: checkMinlength, trigger: 'blur' }],
      maxLength: [{ validator: checkMaxlength, trigger: 'blur' }],
      pattern: [],
    });
    return {
      form,
      formRules,
      ...baseApi,
      handleSubmit: () => {
        handleSubmit(emit);
      },
    };
  },
});
