
import { defineComponent, reactive, computed } from 'vue';
import Base from './Base';
const { handleSubmit, form, source, ...baseApi } = Base();

const INT32_RANGE = [-2147483648, 2147483647];
const INT64_RANGE = [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER];

export default defineComponent({
  name: 'IntSettingDialog',
  setup(props, { emit }) {
    const checkMin = (rule: any, value: any, callback: any) => {
      if ((form.max || Number(form.max) === 0) && (value || Number(value) === 0)) {
        if (value > form.max) {
          callback(new Error('最小值不能大于最大值'));
          return;
        }
      }
      callback();
    };

    const checkMax = (rule: any, value: any, callback: any) => {
      if ((form.min || Number(form.min) === 0) && (value || Number(value) === 0)) {
        if (value < form.min) {
          callback(new Error('最大值不能小于最小值'));
          return;
        }
      }
      callback();
    };

    const formRules = reactive({
      defaultValue: [],
      min: [{ validator: checkMin, trigger: 'change' }],
      max: [{ validator: checkMax, trigger: 'change' }],
    });

    const min = computed(() => {
      if (source.value.type === 'Int32') {
        return INT32_RANGE[0];
      }
      return INT64_RANGE[0];
    });

    const max = computed(() => {
      if (source.value.type === 'Int32') {
        return INT32_RANGE[1];
      }
      return INT64_RANGE[1];
    });
    return {
      form,
      formRules,
      source,
      ...baseApi,
      handleSubmit: () => {
        handleSubmit(emit);
      },
      min,
      max,
    };
  },
});
