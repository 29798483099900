import request from '@/utils/request';
import URL from '@/shared/constant/url';
import { getUrl } from '../utils';
import { SuccessResponse } from '@/types/response';
import SERVER_TYPES from '@/shared/servertype';
import service from '@/shared/constant/url/service';
import { apiProxy } from '../proxy/proxy';
import { DtoModel, DtoApiParams, CreatDtoModel } from '@/views/service-management/dto/types';
import { ServiceSnapshot } from '@/views/service-management/business-service/components/release';

export const addService: (payload: object) => Promise<SuccessResponse<any>> = (payload: object) =>
  request.post(getUrl(URL.service.ADD_SERVICE), payload);

export const postAppsDelete: (id: string) => Promise<SuccessResponse<any>> = (id: string) =>
  request.post(getUrl(URL.service.POST_APPS_DELETE, id));

export const updateService: (id: string, payload: object) => Promise<SuccessResponse<any>> = (
  id: string,
  payload: object,
) => request.post(getUrl(URL.service.UPDATE_SERVICE, id), payload);

export const getServiceById: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_SERVICE_BY_ID, payload.id));

export const getServiceApis: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_SERVICES_APIS), { params: payload });

export const getAppidPerion: (appId: any, payload: object) => Promise<SuccessResponse<any>> = (
  appId: any,
  payload: any,
) => request.get(getUrl(URL.service.GET_APPID_PERMISSION, appId), { params: payload });

export const postAppidPerion: (appId: any, payload: object) => Promise<SuccessResponse<any>> = (
  appId: any,
  payload: object,
) => request.post(getUrl(URL.service.POST_APPID_PERMISSION, appId), payload);

export const postAppsidPerion: (id: any, payload: object) => Promise<SuccessResponse<any>> = (
  id: any,
  payload: object,
) => request.post(getUrl(URL.service.POST_APPS_PERMISSION, id), payload);

export const postDeletePerion: (id: any) => Promise<SuccessResponse<any>> = (id: any) =>
  request.post(getUrl(URL.service.POST_DELETE_PERMISSION, id));

export const getServiceInfoById: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_SERVICE_INFO_BY_ID, payload.id));

export const getServiceList: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_SERVICE_LIST), { params: payload });

export const getAppsMains: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_APPS_MAINS), { params: payload });

export const postAppsMains: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(URL.service.POST_APPS_MAINS), payload);

export const postMainMenus: (id: string, payload: object) => Promise<SuccessResponse<any>> = (
  id: string,
  payload: any,
) => request.post(getUrl(URL.service.POST_MAIN_MENUS, id), payload);

export const getAllServiceList: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_ALL_SERVICE_LIST), { params: payload });

export const getAllServicesModelsList: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_ALL_SERVICEMODEL_LIST), { params: payload });

export const getServiceDependencyList: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_SERVICE_DEPENDENCY_LIST), { params: payload });

export const getAppsMainsTree: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_APPS_MAINS_TREE), { params: payload });

export const deleteService: (id: any) => Promise<SuccessResponse<any>> = (id: string) =>
  request.post(getUrl(URL.service.DELETE_SERVICE), { ids: id });

export const buildService: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(URL.service.BUILD_SERVICE), payload, { timeout: 60000 });

export const initService: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.INIT_SERVICE, payload.serviceId), { timeout: 60000 });

export const getServiceIdApis: (id: string) => Promise<SuccessResponse<any>> = (id: string) =>
  request.get(getUrl(URL.service.GET_SERVICE_APIS), { params: { serviceId: id } });

export const updateServiceApis: (payload: object, id: string) => Promise<SuccessResponse<any>> = (
  payload: any,
  id: string,
) => request.post(getUrl(URL.service.UPDATE_SERVICE_APIS, id), payload);

export const getLogRuntime: (params: object) => Promise<SuccessResponse<any>> = (params: object) =>
  request.get(getUrl(URL.service.GET_LOG_RUNTIME), { params });

export const getLogs: (params: object) => Promise<SuccessResponse<any>> = (params: object) =>
  request.get(getUrl(URL.service.GET_LOG_LIST), { params });

export const getChanges: (id: any) => Promise<SuccessResponse<any>> = (id: string) =>
  request.get(getUrl(URL.service.GET_CHANGES, id));

export const getChangesApply: (serviceId: string) => Promise<SuccessResponse<any>> = (serviceId: string) =>
  request.post(getUrl(URL.service.POST_CHANGES_APPLY), { serviceId });

export const startService: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(URL.service.START_SERVICE), payload, { timeout: 60000 });

export const stopService: (payload: object) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(URL.service.STOP_SERVICE), payload, { timeout: 60000 });

export const serviceNameTest = (payload: any): Promise<SuccessResponse<any>> =>
  request.post(getUrl(URL.service.SERVICE_NAME_TEST), payload);

export const getServiceTraceId: (serviceId: any, serviceType?: any) => Promise<SuccessResponse<any>> = (
  serviceId: any,
  serviceType?: any,
) => request.get(getUrl(URL.service.GET_SERVICE_TRACE_ID), { params: { serviceId, serviceType } });

export const updateServiceStatus: (ids: any) => Promise<SuccessResponse<any>> = (ids: any) =>
  request.post(getUrl(URL.service.UPDATE_SERVICE_STATUS), { ids });

export const getServiceConfig: (id: any) => Promise<SuccessResponse<any>> = (id: any) =>
  request.get(getUrl(URL.service.GET_SERVICE_CONFIG, id));

export const getServiceUpgrade: (id: any) => Promise<SuccessResponse<any>> = (id: any) =>
  request.get(getUrl(URL.service.GET_SERVICE_UPGRADE_SCRIPT, id));

// 发版
export const releaseService: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(URL.service.POST_SERVICE_RELEASE), payload);

// 发版前校验
export const releaseCheck: (serviceId: any) => Promise<SuccessResponse<any>> = (serviceId: any) =>
  request.get(getUrl(URL.service.GET_RELEASE_CHECK), { params: { serviceId } });

// 获取服务接口列表
export const getServiceApiList = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.GET_SERVICE_API_LIST, {
    method: 'GET',
    params: payload,
  });

// 创建服务接口
export const createServiceApi = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.SAVE_SERVICE_API, {
    method: 'POST',
    data: payload,
  });

// 更新服务接口
export const updateServiceApi = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.UPDATE_SERVICE_API, {
    method: 'POST',
    data: payload,
  });

// 删除服务接口
export const delServiceApi = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.DELETE_SERVICE_API, {
    method: 'GET',
    params: payload,
  });

// 获取服务接口
export const findServiceApi = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.GET_SERVICE_API_INFO, {
    method: 'GET',
    params: payload,
  });

// 获取 dto list
export const getAllDtoModel: (serviceId: string) => Promise<SuccessResponse<DtoModel[]>> = (serviceId) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.GET_DTO_MODEL_ALL_LIST, {
    method: 'GET',
    params: {
      serviceId,
    },
  });

// 获取 dto
export const getDtoModel: (params: DtoApiParams) => Promise<SuccessResponse<DtoModel[]>> = (params) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.GET_DTO_MODEL, {
    method: 'GET',
    params,
  });
// 更新 dto
export const updateDtoModelList: (data: DtoModel) => Promise<SuccessResponse<DtoModel[]>> = (data) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.UPDATE_DTO_MODEL, {
    method: 'POST',
    data,
  });
// 新建 dto
export const createDtoModelList: (data: CreatDtoModel) => Promise<SuccessResponse<DtoModel[]>> = (data) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.ADD_DTO_MODEL, {
    method: 'POST',
    data,
  });
// 删除 dto
export const removeDtoModelList: (data: DtoApiParams) => Promise<SuccessResponse<DtoModel[]>> = (data) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.REMOVE_DTO_MODEL, {
    method: 'POST',
    data,
  });

export const dtoModelAPI = {
  findAll: getAllDtoModel,
  findOne: getDtoModel,
  create: createDtoModelList,
  update: updateDtoModelList,
  remove: removeDtoModelList,
};
/**
 * 更新服务参数
 * @param payload
 * @returns
 */
export const saveApiParams = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.SAVE_SERVICE_API_PARAMS, {
    method: 'POST',
    data: payload,
  });

/**
 * 获取服务参数
 * @param payload
 * @returns
 */
export const getApiParams = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.GET_SERVICE_API_PARAMS, {
    method: 'GET',
    params: payload,
  });
export const startCheck: (serviceId: any) => Promise<SuccessResponse<any>> = (serviceId: any) =>
  request.get(getUrl(URL.service.GET_START_CHECK), { params: { serviceId } });

// 获取服务debug url
export const getServiceAPiDebugUrl = (payload: any) =>
  request.get('/service/debug', {
    params: payload,
  });

// apiProxy(SERVER_TYPES.ACCOUNT, service.GET_SERVICE_DEBUG_URL, {
//   method: 'GET',
//   params: payload,
// });

/**
 * 获取服务最新版本
 */

export const getLastVersion: (serviceId: number) => Promise<SuccessResponse<ServiceSnapshot>> = (serviceId: number) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, [`${service.GET_LAST_VERSION}/${serviceId}`], {
    method: 'GET',
  });

// 获取domain-config，服务的上一次配置
export const getDomainConfig: (params: any) => Promise<SuccessResponse<any>> = (params) =>
  request.get(getUrl(URL.service.GET_DOMAIN_CONFIG), { params });

// 发版前后台预置数据准备启动
export const getPreDataStart: (serviceId: number) => Promise<SuccessResponse<any>> = (serviceId: number) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.GET_PRE_DATA_START, {
    method: 'GET',
    params: {
      serviceId,
    },
  });

// 获取发版预置数据的模型列表
export const getModelNames: (serviceId: number) => Promise<SuccessResponse<any>> = (serviceId: number) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.GET_MODEL_NAMES, {
    method: 'GET',
    params: {
      serviceId,
    },
  });

// 发版时，展示数据变更
export const getDataChangesForPublish: (params: any) => Promise<SuccessResponse<any>> = (params: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.GET_DATA_CHANGES, {
    method: 'GET',
    params,
  });

// 发版时，展示数据变更 New
export const getDataChangesNewForPublish: (params: any) => Promise<SuccessResponse<any>> = (params: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.GET_DATA_CHANGES_NEW, {
    method: 'GET',
    params,
  });

// 勾选变更数据
export const dataSelect = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.DATA_SELECT, {
    method: 'POST',
    data: payload,
  });

// 取消变更勾选
export const dataDeSelect = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.DATA_DE_SELECT, {
    method: 'POST',
    data: payload,
  });

// 构建服务快照
export const buildServiceNew = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, service.BUILD_SERVICE_NEW, {
    method: 'POST',
    data: payload,
  });

// 取消发版
export const cancelRelease: (params: any) => Promise<SuccessResponse<any>> = (params: any) =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, service.RELEASE_CANCEL, {
    method: 'GET',
    params,
  });

// 获取可用的外部扩展模型
export const getExternalModelUsable: (serviceId: number) => Promise<SuccessResponse<any>> = (serviceId: number) =>
  request.get(getUrl(URL.service.GET_CANUSE_EXTERNAL), { params: { serviceId } });

// 获取当前服务的外部模型列表
export const getExternalModelList: (serviceId: number) => Promise<SuccessResponse<any>> = (serviceId: number) =>
  request.get(getUrl(URL.service.GET_EXTERNAL_LIST), { params: { serviceId } });

// 导入外部模型
export const doImportExternal: (data: any) => Promise<SuccessResponse<any>> = (data: any) =>
  request.post(getUrl(URL.service.POST_IMPORT_EXTERNAL), data);

// 删除外部模型
export const deleteImportExternal: (data: any) => Promise<SuccessResponse<any>> = (data: any) =>
  request.post(getUrl(URL.service.POST_DELETE_EXTERNAL), data);

// 获取服务依赖列表
export const getDependencyList: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.service.GET_DEPENDENCY_LIST), { params: payload });

// 添加服务依赖
export const addDependency: (data: any) => Promise<SuccessResponse<any>> = (data) =>
  request.post(getUrl(URL.service.ADD_DEPENDENCY), data);

// 编辑服务依赖
export const updateDependency: (serviceId: number, dependency: any) => Promise<SuccessResponse<any>> = (
  serviceId: number,
  dependency: any,
) => request.post(getUrl(URL.service.UPDATE_DEPENDENCY), { serviceId, dependency });

// 删除服务依赖
export const deleteDependency: (serviceId: number, dependency: any) => Promise<SuccessResponse<any>> = (
  serviceId: number,
  dependency: any,
) => request.post(getUrl(URL.service.DELETE_DEPENDENCY), { serviceId, dependency });
