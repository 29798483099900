import { Ref } from 'vue';

export type PrimitiveType = 'String' | 'Int32' | 'Int64' | 'Float' | 'Double' | 'Decimal' | 'Date' | 'Boolean';

export type ComplexType = 'Array' | 'Object';

export type DataType = PrimitiveType | ComplexType;

export type SettingMap = {
  [key in PrimitiveType]: Ref<any>;
};

export interface DtoApiParams {
  serviceId: string;
  uniqueId: string;
}

export const enum ImportType {
  Reference = 1,
  Clone,
}

export const enum DtoType {
  RequestDTO = 1,
  ResponseDTO,
  SharedDTO,
  NestedDTO, //
}

export const enum CollectionType {
  Primitive = 1,
  List,
  Array,
  Set,
}

export const enum DtoSource {
  Other,
  Model,
}

export interface DtoModel {
  serviceId: number;
  apiId: number;
  name: string;
  zhName: string;
  uniqueId: string;
  serviceName: string;
  rootId: number;
  dtoType: DtoType;
  list: DtoProps[];
  source: DtoSource;
}

export type CreatDtoModel = Omit<DtoModel, 'uniqueId' | 'rootId' | 'source'>;

export type DtoTree = Omit<DtoModel, 'list'> & { children: DtoProps[] };

export interface DtoProps {
  uniqueId?: string;
  name: string;
  type: DataType;
  example: any;
  desc: string;
  config: string | null;
  dtoName: string | null;
  validationFormat: string | null;
  dtoId: string;
  propertyOrder: number;
  collectionType: CollectionType;
  serviceName: string;
  importType: number | null;
  children?: DtoProps[] | null;
}

export type IdentifyDtoProp = {
  _id: string;
};

export type PrivatedDtoProps = {
  _parent: LocalDtoProps | null;
  _disabled: boolean;
  _config: DtoPropConfigs;
  _error: { [key in ValidateKeys]?: string };
} & IdentifyDtoProp;

export type DtoPropsOfSelector = DtoProps & IdentifyDtoProp;

export type LocalDtoProps = Omit<DtoProps, 'children'> &
  PrivatedDtoProps & {
    children: LocalDtoProps[] | null;
  };

export type UpdateDtoProps = Partial<DtoProps>;

export interface DtoPropConfigs {
  [key: string]: any;
}

export type SettingChange = (data: { id: string; config: DtoPropConfigs }) => void;

export const enum EditMode {
  Create = 1,
  Update,
}

export type CreatOrUpdateDtoModel = DtoModel | CreatDtoModel;

export type ValidateKeys = keyof Pick<DtoProps, 'name' | 'example'>;

export type Validator = {
  [key in ValidateKeys]: (prop: LocalDtoProps) => string | undefined;
};

export interface DialogOptions {
  onOpen?: Function;
  onClose?: Function;
}

export type PropUIConfig = {
  disable: boolean;
  disableAdd: boolean;
  disableDel: boolean;
  disableImport: boolean;
  disableConfig: boolean;
  widget: string;
};

export type Actions = {
  name: string;
  handler: (row: LocalDtoProps, ...rest: any[]) => void;
  disabled: boolean;
};

export interface ActionsGroupProp {
  actions: Actions[];
  row: LocalDtoProps;
}
