<template>
  <div>
    <el-radio-group v-model="paramsMethod" style="margin-bottom: 20px" @change="handleParamsMethodChange">
      <el-radio-button :label="item" v-for="item in paramsMethods" :key="item" class="param-type">
        {{ item }}
      </el-radio-button>
    </el-radio-group>

    <div class="content-types" v-if="paramsMethod === 'body' && !isResponse">
      <el-radio
        v-model="contentType"
        :label="item"
        v-for="item in contentTypes"
        :key="item"
        :disabled="!isEdit"
        @change="handleContentTypeChange"
      >
        {{ item }}
      </el-radio>
    </div>
    <list-wrap
      :loading="loading"
      :inProject="false"
      :empty="isEmptyList"
      :hasCreateAuth="getShowBool('apiUpdate') && !isRefrenceService && editStatusQuery === 0"
      :handleCreate="handleAdd"
    >
      <el-table
        :data="list"
        style="width: 100%"
        row-key="$id"
        default-expand-all
        :span-method="objectSpanMethod"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        class="params-table"
      >
        <el-table-column prop="name" label="参数" class-name="col-inline is-required">
          <template #default="scope">
            <span v-if="nameIsReadonly(scope.row) || scope.row['ui:nameReadonly']">{{ scope.row.name }}</span>
            <el-input
              placeholder="请输入参数名称"
              v-model.trim="scope.row.name"
              maxlength="50"
              v-else
              :ref="(ref) => (inputRefs[`name.${scope.row.$id}`] = ref)"
              @input="() => clearError(`name.${scope.row.$id}`)"
              @change="(value) => handleInputChange('name', scope.row.$id, value)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="dtoName" align="right">
          <template #default="scope">
            <span v-if="scope.row.dtoName" class="import-info">
              来源：<service-name :name="`${scope.row.serviceName}_${scope.row.dtoName}`"></service-name>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="参数类型" width="180" class-name="is-required">
          <template #default="scope">
            <span v-if="!isEdit || scope.row.readonly || scope.row['ui:typeReadonly']">{{
              getParamTypeName(scope.row.type)
            }}</span>
            <el-select
              v-else
              placeholder="请选择参数类型"
              v-model="scope.row.type"
              @change="
                (value) => {
                  handleTypeChange(scope.row, value);
                }
              "
            >
              <el-option
                v-for="item in scope.row['ui:typeOptions'] ?? paramTypes"
                :disabled="isDisableParamType(item.value, scope.row)"
                :key="item.value"
                :value="item.value"
                :label="item.name"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="required" label="是否必填" width="150" class-name="is-required">
          <template #default="scope">
            <span v-if="!isEdit || scope.row.readonly || scope.row['ui:requiredReadonly']">{{
              scope.row.config.required ? '是' : '否'
            }}</span>
            <el-select
              placeholder="请选择"
              v-model="scope.row.config.required"
              v-else-if="scope.row?.config?.hasOwnProperty('required')"
            >
              <el-option
                v-for="item in paramRequireds"
                :key="item.value"
                :value="item.value"
                :label="item.name"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="example" label="参数示例">
          <template #default="scope">
            <span v-if="!isEdit || scope.row.type === 'Array' || scope.row.type === 'Object'">{{
              scope.row.example
            }}</span>
            <el-input
              v-else
              placeholder="请输入参数示例"
              v-model.trim="scope.row.example"
              minlength="1"
              maxlength="20"
              :ref="(ref) => (inputRefs[`example.${scope.row.$id}`] = ref)"
              @input="() => clearError(`example.${scope.row.$id}`)"
              @change="(value) => handleInputChange('example', scope.row.$id, value)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="参数描述">
          <template #default="scope">
            <tooltip v-if="!isEdit" :content="scope.row.desc"></tooltip>
            <el-input
              v-else
              placeholder="请输入参数描述"
              v-model.trim="scope.row.desc"
              maxlength="512"
              :ref="(ref) => (inputRefs[`desc.${scope.row.$id}`] = ref)"
              @input="() => clearError(`desc.${scope.row.$id}`)"
              @change="(value) => handleInputChange('desc', scope.row.$id, value)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="actions" label="操作" align="right" width="150">
          <template #default="scope">
            <div v-show="scope.row['ui:showActions'] ?? true">
              <template v-if="isEdit && getShowBool('apiUpdate')">
                <el-button
                  type="text"
                  @click="handleAdd(scope.row)"
                  v-if="canAdd(scope.row) && (scope.row['ui:showAddAction'] ?? true) && !scope.row.readonly"
                  >添加</el-button
                >

                <el-dropdown
                  v-if="scope.row.type === 'Object' && !scope.row.readonly"
                  trigger="click"
                  style="margin: 0 5px"
                >
                  <el-button type="text"
                    >引入<i class="el-icon-arrow-down el-icon--right" style="margin-left: 0"></i
                  ></el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item key="1" @click="handleOpenDto(scope.row, 1)">只读引入</el-dropdown-item>
                      <el-dropdown-item key="2" @click="handleOpenDto(scope.row, 2)">克隆引入</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>

                <el-button
                  type="text"
                  v-else-if="scope.row.type !== 'Array' && scope.row.type !== 'Object' && !scope.row.readonly"
                  @click="handleSetting(scope.row)"
                  >设置</el-button
                >
                <el-button type="text" @click="handleRemove(scope.row)" v-if="canDel(scope.row) && !scope.row.readonly"
                  >删除</el-button
                >
              </template>
              <template v-else>
                <el-button
                  type="text"
                  v-if="scope.row.type !== 'Array' && scope.row.type !== 'Object'"
                  @click="handleSetting(scope.row)"
                  >查看设置</el-button
                >
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="error-wrap">{{ formError }}</div>
    </list-wrap>

    <div class="code-preview">
      <h3 class="code-preview__title">
        预览
        <el-button type="text" @click="handlePreview" :disabled="list.length === 0">生成预览</el-button>
      </h3>
      <pre
        v-highlight
        v-if="list.length > 0"
      ><code v-html="previewCode" class="json" style="background: #f5f5f5; padding: 10px;"></code></pre>
    </div>

    <div class="params-form-btns" v-if="getShowBool('apiUpdate') && !isRefrenceService">
      <el-button
        type="primary"
        @click="handleToggleEdit(true)"
        v-if="!isEdit && list.length > 0 && !isFromServiceDetail && editStatusQuery === 0"
        >编辑</el-button
      >
      <template v-else-if="isEdit">
        <el-button type="primary" @click="handleSave" :loading="submitting">确定</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </template>
    </div>

    <dto-list-dialog ref="dtoListDialog" @on-confirm="handleDtoConfirm" />
    <stringSettingDialog ref="stringSettingDialog" @change="handleConfigChange" />
    <intSettingDialog ref="intSettingDialog" @change="handleConfigChange" />
    <floatSettingDialog ref="floatSettingDialog" @change="handleConfigChange" />
    <dateSettingDialog ref="dateSettingDialog" @change="handleConfigChange" />
    <booleanSettingDialog ref="booleanSettingDialog" @change="handleConfigChange" />
  </div>
</template>
<script>
import { defineComponent, ref, computed, watch, reactive, toRef, onMounted } from 'vue';

import qs from 'qs';
import {
  getParamsMethods,
  postParamsMethods,
  CONTENT_TYPES,
  getParamTypeName,
  PARAMS_TYPES_QUERY,
  PARAMS_TYPE_FORM_DATA,
  PARAMS_TYPE_BODY,
  PARAMS_TYPE_RESPONSE,
} from './config';
import StringSettingDialog from './settings/String.vue';
import FloatSettingDialog from './settings/Float.vue';
import IntSettingDialog from './settings/Int.vue';
import DateSettingDialog from './settings/Date.vue';
import BooleanSettingDialog from './settings/Boolean.vue';
import DtoListDialog from '../dto/Index.vue';
import {
  genParam,
  findAndUpdateParams,
  paramsToExample,
  validParams,
  validName,
  validExample,
  validDescription,
  genTreeDefine,
  // dtoToParams,
  dtoToEditedParams,
  paramsToSaveData,
  responseToParams,
  useBodyStructure,
} from './util';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getApiParams, saveApiParams } from '@/api/servers';
import _ from 'lodash';
import { getShowBool } from '@/utils/permission-show-module';
import { useCheckRefrenceService } from '../business-service/utils/permisson';
import { useRoute, useRouter } from 'vue-router';
const POST_METHODTYPES = ['POST', 'PUT', 'PATCH'];
const GET_METHODTYPES = ['GET', 'DELETE'];

export default defineComponent({
  name: 'ParamsList',
  components: {
    StringSettingDialog,
    FloatSettingDialog,
    IntSettingDialog,
    DateSettingDialog,
    BooleanSettingDialog,
    DtoListDialog,
  },
  props: {
    isResponse: {
      type: Boolean,
      default: false,
    },
    apiInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    serviceInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { apiInfo } = props;
    const loading = ref(false);
    const methodType = ref((apiInfo.methodType || '').toLowerCase());
    const paramsMethod = ref(POST_METHODTYPES.includes(apiInfo.methodType) || props.isResponse ? 'body' : 'query');
    const contentType = ref('json');
    const editStatusQuery = ref(0);
    const inputRefs = ref({});
    const formError = ref('');
    const previewCode = ref('');
    const stringSettingDialog = ref(null);
    const intSettingDialog = ref(null);
    const floatSettingDialog = ref(null);
    const dateSettingDialog = ref(null);
    const booleanSettingDialog = ref(null);
    const dtoListDialog = ref(null);
    const isEdit = ref(false);
    const paramsDefine = ref(null);
    const submitting = ref(false);
    const sourceData = ref(null);
    const isFromServiceDetail = ref(false);
    const listKeys = ['query', 'headers', ...CONTENT_TYPES];
    const route = useRoute();
    const router = useRouter();

    watch(
      () => route.query.from,
      (v) => {
        if (v === 'ServiceDetail') {
          isFromServiceDetail.value = true;
        }
      },
      {
        immediate: true,
      },
    );
    onMounted(() => {
      if (router) {
        editStatusQuery.value = parseInt(router.currentRoute.value.query.editStatus);
      }
    });
    const listMap = reactive({
      // body: [],
      query: [],
      headers: [],
    });
    listKeys.forEach((key) => (listMap[key] = []));

    const currentListKey = computed(() => {
      if (paramsMethod.value === 'body') {
        return contentType.value;
      }
      return paramsMethod.value;
    });

    const list = computed(() => listMap[currentListKey.value]);

    const paramsMethods = computed(() => {
      if (props.isResponse) {
        return [];
      }
      if (GET_METHODTYPES.includes(apiInfo.methodType)) {
        return getParamsMethods;
      }
      return postParamsMethods;
    });

    watch(
      () => props.apiInfo,
      (newValue) => {
        methodType.value = (newValue.methodType || 'get').toLowerCase();
      },
    );
    // request body is json type
    const isResponse = toRef(props, 'isResponse');
    const { getInitialParamsList, isJsonRequestBody } = useBodyStructure({
      isResponse,
      curParamsStyle: paramsMethod,
      curContentType: contentType,
      currentList: list,
    });
    // 获取接口参数
    const fetchApiParams = async () => {
      loading.value = true;
      try {
        const { data } = await getApiParams({
          serviceId: apiInfo.serviceId,
          apiUniqueId: apiInfo.uniqueId,
          type: props.isResponse ? 2 : 1,
        });
        const res = responseToParams(data?.data ?? []);
        sourceData.value = _.cloneDeep(res);
        console.log({ sourceData: sourceData.value });
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        resetListMap();
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
    };

    fetchApiParams();

    // 数据重置
    const resetListMap = () => {
      const res = _.cloneDeep(sourceData.value);
      listKeys.forEach((key) => (listMap[key] = []));
      // eslint-disable-next-line no-restricted-syntax
      postParamsMethods.forEach((k) => {
        const item = res[k];

        if (item) {
          if (k === 'body') {
            contentType.value = item.contentType || 'json';
          }
          listMap[k] = getInitialParamsList({
            initialList: item.list,
            dtoId: item.dtoId,
            paramsStyle: k,
          });
        }
      });

      if (!contentType.value || props.isResponse) {
        contentType.value = 'json';
      }
      if (!paramsMethod.value || props.isResponse) {
        paramsMethod.value = 'body';
      }
      listMap[contentType.value] = listMap.body || [];
      delete listMap.body;
      console.log(listMap);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateParamsDefine();
    };

    // 更新节点描述
    const updateParamsDefine = () => {
      paramsDefine.value = genTreeDefine(listMap[currentListKey.value]);
    };

    const handleParamsMethodChange = () => {
      formError.value = '';
      previewCode.value = '';
      updateParamsDefine();
    };

    // 编辑态切换
    const handleToggleEdit = (value) => {
      isEdit.value = value;
    };

    // 添加
    const handleAdd = (row) => {
      if (!row.$id) {
        handleToggleEdit(true);
        const initiallList = getInitialParamsList({
          paramsStyle: paramsMethod.value,
        });
        listMap[currentListKey.value].push(...initiallList);
      } else {
        findAndUpdateParams(listMap[currentListKey.value], row.$id, (items, index) => {
          items.splice(index + 1, 0, genParam());
        });
      }
      updateParamsDefine();
    };

    // 移除
    const handleRemove = (row) => {
      findAndUpdateParams(listMap[currentListKey.value], row.$id, (items, index) => {
        items.splice(index, 1);
      });
      updateParamsDefine();
    };

    // 类型改变
    const handleTypeChange = (row, value) => {
      const define = paramsDefine.value[row.$id];
      const oldType = define.type;
      const afterTypeChange = () => {
        if (value === 'Array' || value === 'Object') {
          const children = [];
          if (value === 'Array') {
            children.push(
              genParam({
                name: 'item',
              }),
            );
          } else {
            children.push(genParam());
          }
          findAndUpdateParams(listMap[currentListKey.value], row.$id, (items, index, item) => {
            items.splice(index, 1, {
              ...item,
              example: '',
              config: { required: 1 },
              importType: null, // 对象这里可能是只读引入的，所以更改后应该把这个标记去掉
              children,
            });
          });
        } else {
          findAndUpdateParams(listMap[currentListKey.value], row.$id, (items, index, item) => {
            // eslint-disable-next-line no-param-reassign
            delete item.children;
            items.splice(index, 1, item);
          });
        }
        updateParamsDefine();
      };
      if (['Array', 'Object'].includes(oldType)) {
        ElMessageBox.confirm('是否确认对属性进行修改？修改后将清除该属性子属性', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            afterTypeChange();
          })
          .catch(() => {
            // eslint-disable-next-line no-param-reassign
            row.type = oldType;
          });
      } else {
        afterTypeChange();
      }
    };

    // 保存
    const handleSave = async () => {
      formError.value = '';
      const saveListMap = {
        body: listMap[contentType.value],
        query: listMap.query,
        headers: listMap.headers,
      };
      const error = Object.entries(saveListMap).some(([key, list]) => {
        const defineKey = key === 'body' ? contentType.value : key;
        const defines = genTreeDefine(listMap[defineKey]);
        const res = validParams(list, defines);
        if (res) {
          const error = res[0];
          try {
            const ref = inputRefs.value[`${error.field}.${error.id}`];
            ref.$el.classList.add('is-error');
            ref.focus();
          } catch (e) {}
          formError.value = error.message;
          return true;
        }
        return false;
      });
      if (error) {
        return false;
      }

      const baseInfo = {
        serviceId: apiInfo.serviceId,
        apiId: apiInfo.uniqueId,
      };
      const saveData = [];
      const keys = props.isResponse ? ['body'] : Object.keys(saveListMap);
      keys.forEach((key) => {
        let list; // list 包含dtoId
        let preservedList; // list 包含默认结构，真正保存的时候去掉默认结构
        if (props.isResponse) {
          list = saveListMap[key].find((e) => e.name === 'data');
          preservedList = list?.children ?? [];
        } else if (contentType.value === 'json' && key === 'body') {
          [list] = saveListMap[key];
          preservedList = list?.children ?? [];
        } else {
          list = saveListMap[key];
          preservedList = list;
        }
        const dtoId = list?.dtoId ?? null;
        saveData.push({
          ...baseInfo,
          dtoId,
          paramIn: _.startCase(key),
          contentType: key === 'body' ? contentType.value : '',
          list: paramsToSaveData(preservedList),
        });
      });

      try {
        submitting.value = true;
        await saveApiParams({
          data: saveData,
          type: props.isResponse ? 2 : 1,
        });
        ElMessage.success('保存成功');
        isEdit.value = false;
        fetchApiParams();
      } catch (e) {}
      submitting.value = false;
    };

    // 清除错误
    const clearError = (refId) => {
      try {
        const ref = inputRefs.value[refId];
        ref.$el.classList.remove('is-error');
      } catch (e) {
        console.log(e);
      }
    };

    // 取消
    const handleCancel = () => {
      handleToggleEdit(false);
      clearError();
      formError.value = '';
      resetListMap();
    };

    // validate after input change
    const handleInputChange = (field, id, value) => {
      formError.value = '';
      let valid = false;
      switch (field) {
        case 'name':
          valid = validName(value, {
            id,
            defines: paramsDefine.value,
            list: list.value,
          });
          break;
        case 'example':
          valid = validExample(value);
          break;
        case 'desc':
          valid = validDescription(value);
          break;
      }
      if (valid) {
        try {
          const ref = inputRefs.value[`${field}.${id}`];
          ref.$el.classList.add('is-error');
        } catch (e) {}
        formError.value = valid;
      }
    };

    // 设置
    const handleSetting = (row) => {
      let ref = null;
      switch (row.type) {
        case 'String':
          ref = stringSettingDialog.value;
          break;
        case 'Float':
        case 'Double':
          ref = floatSettingDialog.value;
          break;
        case 'Int32':
        case 'Int64':
          ref = intSettingDialog.value;
          break;
        case 'Boolean':
          ref = booleanSettingDialog.value;
          break;
        case 'Date':
          ref = dateSettingDialog.value;
          break;
      }
      if (ref) {
        ref.handleOpen(row, isEdit.value);
      }
    };

    // 设置
    const handleConfigChange = ({ id, config }) => {
      findAndUpdateParams(listMap[currentListKey.value], id, (items, index, item) => {
        items.splice(index, 1, {
          ...item,
          config: {
            ...config,
          },
        });
      });
    };

    // 可选的参数类型
    const paramTypes = computed(() => {
      if (props.isResponse) {
        return [...PARAMS_TYPE_RESPONSE];
      }
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const _contentType = contentType.value;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const _paramMethod = paramsMethod.value;
      if (_paramMethod === 'query' || _paramMethod === 'headers') {
        return [...PARAMS_TYPES_QUERY];
      }
      if (_paramMethod === 'body') {
        switch (_contentType) {
          case 'form-data':
            return [...PARAMS_TYPE_FORM_DATA];
          case 'x-www-form-urlencoded':
            return [...PARAMS_TYPES_QUERY];
        }
        return [...PARAMS_TYPE_BODY];
      }
      return [...PARAMS_TYPE_RESPONSE];
    });

    // 禁用类型
    const isDisableParamType = (type, row) => {
      if ((type !== 'Array' && type !== 'Object') || !paramsDefine.value) {
        return false;
      }
      const define = paramsDefine.value[row.$id];
      if (define && define.level >= 3) {
        let { parent } = define;
        let step = 2;
        while (parent) {
          if (parent.type !== type) {
            return false;
          }
          if (step === 0) {
            break;
          }
          step -= 1;
          parent = parent.parent;
        }
        return true;
      }
      return false;
    };

    // 预览
    const handlePreview = () => {
      try {
        const currentList = listMap[currentListKey.value];

        const json = paramsToExample(
          // eslint-disable-next-line no-nested-ternary
          props.isResponse ? currentList : isJsonRequestBody.value ? currentList[0].children : currentList,
          {},
        );
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const _paramMethod = paramsMethod.value;
        if (!props.isResponse && _paramMethod === 'query') {
          previewCode.value = qs.stringify(json, { arrayFormat: 'brackets' });
        } else {
          previewCode.value = JSON.stringify(json, null, 4);
        }
      } catch (e) {
        console.log(e);
        ElMessage.error(e.message);
      }
    };

    // 是否可添加
    const canAdd = (row) => {
      if (!paramsDefine.value || !paramsDefine.value[row.$id]) {
        return true;
      }
      const define = paramsDefine.value[row.$id];
      if (define.parent && (define.parent.type === 'Array' || define.parent.isReadonlyImport)) {
        return false;
      }
      return true;
    };

    // 是否可删除
    const canDel = (row) => {
      // 引用只读
      if (!paramsDefine.value || !paramsDefine.value[row.$id]) {
        return true;
      }
      const define = paramsDefine.value[row.$id];
      if (define.parent) {
        const { type, length, isReadonlyImport } = define.parent;
        if (isReadonlyImport || type === 'Array' || (type === 'Object' && length === 1)) {
          return false;
        }
      }
      return true;
    };

    const currentQuoteParamId = ref(null);
    // 引入方式
    const currentQuoteType = ref(1);

    // 打开DTO模态框
    const handleOpenDto = (row, type) => {
      currentQuoteParamId.value = row.$id;
      currentQuoteType.value = type;
      dtoListDialog.value.openDtoList();
    };

    // DTO确定
    const handleDtoConfirm = (row) => {
      const currentParamId = currentQuoteParamId.value;
      if (!currentParamId) {
        return;
      }
      const params = dtoToEditedParams(row, currentQuoteType.value);
      findAndUpdateParams(listMap[currentListKey.value], currentParamId, (items, index, item) => {
        const newItem = { ...item };
        newItem.children = [...params];
        newItem.dtoId = currentQuoteType.value === 1 ? row.uniqueId : null; // 克隆引入不必保存 dtoId，否则后端接口会识别为只读引入
        newItem.importType = currentQuoteType.value;
        items.splice(index, 1, newItem);
      });
      updateParamsDefine();
    };

    // 合并单元格
    const objectSpanMethod = ({ row, columnIndex }) => {
      if (!row.dtoName || !row.readonly) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 2,
          };
        }
        if (columnIndex === 1) {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    };

    // contentType change
    const handleContentTypeChange = () => {
      formError.value = '';
      previewCode.value = '';
      updateParamsDefine();
    };

    // 名称是否只读
    const nameIsReadonly = (row) => {
      const define = paramsDefine.value[row.$id];
      return !isEdit.value || row.readonly || (define?.name === 'item' && define?.parent?.type === 'Array');
    };
    const { isRefrenceService } = useCheckRefrenceService(ref(props.serviceInfo));

    const isEmptyList = computed(() =>
      props.isResponse
        ? list.value.length === 0 || !list.value.some((e) => e.name === 'data')
        : list.value.length === 0,
    );
    return {
      methodType,
      paramsMethod,
      paramsMethods,
      paramTypes,
      contentTypes: [...CONTENT_TYPES],
      contentType,
      paramRequireds: [
        {
          name: '是',
          value: 1,
        },
        {
          name: '否',
          value: 0,
        },
      ],
      loading,
      list,
      previewCode,
      inputRefs,
      formError,
      isEdit,
      handleToggleEdit,
      getParamTypeName,
      handleAdd,
      handleRemove,
      handleTypeChange,
      handleSave,
      clearError,
      handleInputChange,
      handleSetting,
      handleConfigChange,
      handleCancel,
      stringSettingDialog,
      intSettingDialog,
      floatSettingDialog,
      booleanSettingDialog,
      dateSettingDialog,
      dtoListDialog,
      paramsDefine,
      isDisableParamType,
      handlePreview,
      handleOpenDto,
      handleDtoConfirm,
      canAdd,
      canDel,
      handleParamsMethodChange,
      objectSpanMethod,
      submitting,
      getShowBool,
      handleContentTypeChange,
      nameIsReadonly,
      isRefrenceService,
      isFromServiceDetail,
      isEmptyList,
      isJsonRequestBody,
      listMap,
      editStatusQuery,
    };
  },
});
</script>
<style lang="scss" scoped>
.param-type {
  text-transform: capitalize;
}
.content-types {
  margin: 5px 0 20px 0;
  display: flex;
  align-items: center;
}
.params-table {
  :deep(.el-select),
  :deep(.el-input) {
    width: 100%;
  }
  :deep(.is-error .el-input__inner) {
    border-color: #f56c6c;
  }
  .import-info {
    color: #999;
    :deep(.sa-tooltip__content) {
      right: 0;
    }
  }
}
.params-form-btns {
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-wrap {
  color: #f56c6c;
  height: 20px;
  line-height: 20px;
}
</style>
