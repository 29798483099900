
import { computed, defineComponent, reactive } from 'vue';
import Base from './Base';
const { handleSubmit, source, form, ...baseApi } = Base();

const FLOAT_RANGE = [-3.4e38, 3.4e38];
const DOUBLE_RANGE = [-1.79e308, 1.79e308];
export default defineComponent({
  name: 'FloatSettingDialog',
  setup(props, { emit }) {
    const checkMin = (rule: any, value: any, callback: any) => {
      if ((form.max || Number(form.max) === 0) && (value || Number(value) === 0)) {
        if (value > form.max) {
          callback(new Error('最小值不能大于最大值'));
          return;
        }
      }
      callback();
    };

    const checkMax = (rule: any, value: any, callback: any) => {
      if ((form.min || Number(form.min) === 0) && (value || Number(value) === 0)) {
        if (value < form.min) {
          callback(new Error('最大值不能小于最小值'));
          return;
        }
      }
      callback();
    };

    const formRules = reactive({
      defaultValue: [],
      min: [{ validator: checkMin, trigger: 'blur' }],
      max: [{ validator: checkMax, trigger: 'blur' }],
      precision: [],
    });

    const min = computed(() => {
      if (source.value.type === 'Float') {
        return FLOAT_RANGE[0];
      }
      return DOUBLE_RANGE[0];
    });

    const max = computed(() => {
      if (source.value.type === 'Float') {
        return FLOAT_RANGE[1];
      }
      return DOUBLE_RANGE[1];
    });

    return {
      formRules,
      ...baseApi,
      form,
      source,
      min,
      max,
      handleSubmit: () => {
        handleSubmit(emit);
      },
    };
  },
});
