import { defineComponent, PropType, toRefs } from 'vue';
import { ImportType, LocalDtoProps, Actions } from '../types';

const IMPORT_TYPES = [
  {
    name: '只读引入',
    value: ImportType.Reference,
  },
  {
    name: '克隆引入',
    value: ImportType.Clone,
  },
];

export default function <T = any>() {
  return defineComponent({
    props: {
      options: {
        type: Array as PropType<Array<{ value: T; label: string }>>,
        required: true,
      },
      modelValue: {
        type: null as any as PropType<T | undefined>,
        default: undefined as any,
      },
      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },
    name: 'SelectWidgets',
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
      const opts = props.options.map((e) => <el-option {...e} key={e.value}></el-option>);

      return () => (
        <el-select
          placeholder="请选择"
          model-value={props.modelValue}
          disabled={props.disabled}
          onChange={(v: T) => {
            emit('update:modelValue', v);
            if (props.modelValue !== v) {
              emit('change', v);
            }
          }}
        >
          {opts}
        </el-select>
      );
    },
  });
}

export const ActionsGroup = defineComponent({
  name: 'ActionsGroup',
  props: {
    actions: {
      type: Array as PropType<Actions[]>,
      default: () => [],
    },
    row: {
      type: Object as PropType<LocalDtoProps>,
      required: true,
    },
  },
  setup(props) {
    // eslint-disable-next-line prefer-destructuring
    const { row, actions } = toRefs(props);
    return () =>
      actions.value.map((action) => {
        if (action.name === '引入') {
          const slots = {
            default: () => (
              <el-button type="text" disabled={action.disabled}>
                {action.name}
              </el-button>
            ),
            dropdown: () => (
              <el-dropdown-menu>
                {IMPORT_TYPES.map((e) => (
                  <el-dropdown-item
                    key={e.value}
                    onClick={() => {
                      action.handler(row.value, e.value);
                    }}
                  >
                    {e.name}
                  </el-dropdown-item>
                ))}
              </el-dropdown-menu>
            ),
          };
          return (
            <el-dropdown trigger="click" style="margin: 0 5px" disabled={action.disabled} v-slots={slots}></el-dropdown>
          );
        }
        return (
          <el-button
            type="text"
            onClick={() => {
              action.handler(row.value);
            }}
            disabled={action.disabled}
          >
            {action.name}
          </el-button>
        );
      });
  },
});
