/* eslint-disable no-param-reassign */
import {
  ImportType,
  LocalDtoProps,
  PrimitiveType,
  DtoProps,
  PrivatedDtoProps,
  DtoModel,
  DataType,
  SettingMap,
  Validator,
  Actions,
  SettingChange,
  ValidateKeys,
  CreatDtoModel,
} from './types';
import { useDtoList } from './dto-list';
import { ref, Ref } from 'vue';
import StringSettingDialog from '../api-params/settings/String.vue';
import FloatSettingDialog from '../api-params/settings/Float.vue';
import IntSettingDialog from '../api-params/settings/Int.vue';
import DateSettingDialog from '../api-params/settings/Date.vue';
import BooleanSettingDialog from '../api-params/settings/Boolean.vue';
import SelectDto from './SelectDto.vue';
import { genId } from '@/utils/util';
import { isKeyword } from '@/utils/keyword';
import { cloneDeep, omit } from 'lodash';

import { PARAMS_TYPE_BODY } from '../api-params/config';
import { ElMessage } from 'element-plus';

export const DEFAULT_CONFIG = '{"required":1}';
export const DATA_TYPES = PARAMS_TYPE_BODY.map((e) => ({ value: e.value, label: e.name }));
export const BOOLEAN_OPTIONS = [
  {
    label: '是',
    value: 1,
  },
  {
    label: '否',
    value: 0,
  },
];

// -----------------------------------------tools

export const isComplex = (schema: DtoProps) => schema.type === 'Array' || schema.type === 'Object';

export const isPrimitive = (schema: DtoProps) => !isComplex(schema);

export const isArrayItem = (prop: LocalDtoProps) => prop?._parent?.type === 'Array';
// eslint-disable-next-line generator-star-spacing
export function* traversalDtoProps<T extends DtoProps>(list: T[]): IterableIterator<T> {
  for (const node of list) {
    yield node;
    // 这里不需要遍历只读引入节点的所有子属性 （importType === ImportType.Reference）
    if (node.children && node.children.length && node.importType !== ImportType.Reference) {
      // eslint-disable-next-line yield-star-spacing
      yield* traversalDtoProps(node.children as T[]);
    }
  }
}

export const initProp = (prop: DtoProps, parent?: LocalDtoProps, isReference?: boolean) => {
  const res = prop;
  const privateProp: PrivatedDtoProps = {
    _id: genId(),
    _parent: parent ?? null,
    _disabled: Boolean(isReference),
    _config: JSON.parse(prop.config ?? DEFAULT_CONFIG),
    _error: {},
  };

  return Object.assign(res, privateProp) as LocalDtoProps;
};

export const getDtoById = (id: string) => {
  const { dtoList } = useDtoList();
  if (dtoList.value) {
    return dtoList.value.find((e) => e.uniqueId === id);
  }
};

export const getList = (list: DtoProps[], parent?: LocalDtoProps, isReference?: boolean) => {
  function getNode(propData: DtoProps) {
    if (propData.children) {
      let nextFlag = isReference;
      if (propData.importType === ImportType.Reference) {
        nextFlag = true;
      }
      // eslint-disable-next-line no-param-reassign
      propData.children = getList(propData.children, propData as LocalDtoProps, nextFlag);
    }
    const res: LocalDtoProps = { ...initProp(propData, parent, isReference) };
    return res;
  }
  return cloneDeep(list).map(getNode);
};

export const createProp = (parent?: LocalDtoProps) => {
  const EMPTY_PROP: DtoProps = {
    name: '',
    type: 'String',
    example: '',
    desc: '',
    validationFormat: null,
    dtoId: '',
    dtoName: '',
    propertyOrder: 0,
    collectionType: 1,
    config: DEFAULT_CONFIG,
    serviceName: '',
    children: null,
    importType: null,
  };
  return initProp(EMPTY_PROP, parent);
};

export const toDtoPropsList = (list: LocalDtoProps[], currentDto: DtoModel | CreatDtoModel): DtoProps[] => {
  function getNode(propData: LocalDtoProps) {
    if (propData.children) {
      if (propData.importType === ImportType.Reference) {
        propData.children = null;
      } else {
        propData.children = toDtoPropsList(propData.children, currentDto) as LocalDtoProps[];
      }
    }
    const config = JSON.stringify(propData._config);

    const removedKeys: Array<keyof PrivatedDtoProps> = ['_config', '_disabled', '_id', '_parent', '_error'];
    const res = {
      ...omit(propData, removedKeys),
      config,
      // 所有属性添加当前 dto 信息
      dtoName: currentDto.name,
      serviceName: currentDto.serviceName,
    };

    return res as DtoProps;
  }
  return cloneDeep(list).map(getNode);
};

export const getExample = (prop: DtoProps) => {
  if (isPrimitive(prop)) {
    return prop.example;
  }
  if (!prop.children) {
    return prop.type === 'Object' ? {} : [];
  }
  return null;
};

export const toPlainObject = (list: LocalDtoProps[], res: any): any =>
  list.reduce((acc, cur) => {
    const nextValue = cur.children ? toPlainObject(cur.children, cur.type === 'Array' ? [] : {}) : getExample(cur);
    if (Array.isArray(res)) {
      return [...acc, nextValue];
    }
    return {
      ...acc,
      [cur.name]: nextValue,
    };
  }, res);

export const sliceCyclicProp = (list: DtoProps[]) =>
  list.map((prop) => ({
    ...prop,
    children:
      prop.children?.filter((childProp) => !(childProp.dtoId === prop.dtoId && childProp.type === 'Object')) ?? null,
  }));
// ------------------------------------------hooks

export const useSetting = () => {
  const stringSettingDialog = ref<InstanceType<typeof StringSettingDialog>>();

  const floatSettingDialog = ref<InstanceType<typeof FloatSettingDialog>>();

  const booleanSettingDialog = ref<InstanceType<typeof BooleanSettingDialog>>();

  const intSettingDialog = ref<InstanceType<typeof IntSettingDialog>>();

  const dateSettingDialog = ref<InstanceType<typeof DateSettingDialog>>();

  const SETTING_COMPONENT_MAP: SettingMap = {
    String: stringSettingDialog,
    Float: floatSettingDialog,
    Double: floatSettingDialog,
    Decimal: floatSettingDialog,
    Boolean: booleanSettingDialog,
    Int32: intSettingDialog,
    Int64: intSettingDialog,
    Date: dateSettingDialog,
  };

  return {
    SETTING_COMPONENT_MAP,
    stringSettingDialog,
    floatSettingDialog,
    booleanSettingDialog,
    intSettingDialog,
    dateSettingDialog,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const usePropList = (list: Ref<LocalDtoProps[]>, currentDto: DtoModel | CreatDtoModel) => {
  const { SETTING_COMPONENT_MAP, ...settingRefs } = useSetting();

  const getPropById = (id: string) => [...traversalDtoProps<LocalDtoProps>(list.value)].find((e) => e._id === id);

  // update type
  const handleTypeChange = (type: DataType, row: LocalDtoProps) => {
    const prop = getPropById(row._id);
    if (!prop) return;
    if (type === 'Array') {
      const item = createProp(prop);
      item.name = 'item'; // 数组默认 name 是 item
      prop.children = [item];
    } else if (type === 'Object') {
      prop.children = [createProp(prop)];
    } else {
      prop.children = null;
    }
  };

  // add
  const handleAdd = (row?: LocalDtoProps) => {
    if (row) {
      // find parent
      const parent = row._parent ? getPropById(row._parent._id) : null;
      if (parent?.children) {
        parent.children.push(createProp(parent));
      } else {
        // root
        list.value.push(createProp());
      }
    } else {
      // add to root
      list.value.push(createProp());
    }
  };

  // remove
  const handleRemove = (row: LocalDtoProps) => {
    const parent = row._parent ? getPropById(row._parent._id) : null;
    if (parent?.children) {
      // fix:复杂类型不允许有空属性
      if (parent.children.length === 1 && parent.type === 'Object') {
        //
        ElMessage.error('请至少保留一个子属性或者更改父级属性类型');
        return;
      }
      parent.children = parent.children.filter((e) => e._id !== row._id);
    } else {
      // root
      // eslint-disable-next-line no-param-reassign
      list.value = list.value.filter((e) => e._id !== row._id);
    }
  };

  // open config
  const handleSetting = (row: LocalDtoProps) => {
    const ref = SETTING_COMPONENT_MAP[row.type as PrimitiveType];
    if (ref) {
      // 这里需要转换适配
      const data = {
        $id: row._id,
        config: row._config,
      };
      ref.value.handleOpen(data, true);
    }
  };

  // update config
  const handleConfigChange: SettingChange = ({ id, config }) => {
    const prop = getPropById(id);
    if (prop) {
      prop._config = { ...prop._config, ...config };
    }
  };
  // import
  const importType = ref<ImportType>();
  const importedRowId = ref<string>(); // 引入行 id
  const selectDtoRef = ref<InstanceType<typeof SelectDto>>();

  const handleImport = (data: DtoModel) => {
    if (!importedRowId.value) return;
    const prop = getPropById(importedRowId.value);
    if (!prop) return;

    prop.importType = importType.value ?? null; // 标记当前row 引用类型

    if (importType.value === ImportType.Reference) {
      // 只读引用 , 展示被选择 dto 所有的子属性,属性表现为只读，没有任何操作
      prop.dtoId = data.uniqueId;
      // 只读引入的时候需要判断是否构成循环引用，构成的话只显示被引用对象的第一层数据
      // 选择的dto中，后台已经做过一次剪枝，前端需要处理一种特殊情况
      const list = sliceCyclicProp(data.list);
      prop.children = [...getList(list, prop, true)];
    }
    if (importType.value === ImportType.Clone) {
      // copy dto 下面所有的子属性到当前选择的属性下面
      if (prop.children) {
        // fixed #96512269 这里之前的需求是合并，改为替换
        prop.children = [...getList(data.list, prop)];
      }
    }
  };

  const getActions = (prop: LocalDtoProps) => {
    const actions: Actions[] = [
      {
        name: '添加', // 这里的添加都是添加兄弟节点
        handler: handleAdd,
        disabled: (prop._parent?.type === 'Array' && prop._parent.children?.length === 1) || prop._disabled,
      },
      {
        name: '引入',
        handler: (row: LocalDtoProps, type: ImportType) => {
          // open select dto
          if (selectDtoRef.value) {
            selectDtoRef.value.openDialog();
            importType.value = type;
            importedRowId.value = row._id;
          }
        },
        disabled: prop.type !== 'Object' || prop._disabled,
      },
      {
        name: '设置',
        handler: handleSetting,
        disabled: isComplex(prop) || prop._disabled,
      },
      {
        name: '删除',
        handler: handleRemove,
        disabled: prop._disabled,
      },
    ];
    return actions;
  };
  return {
    getPropById,
    handleTypeChange,
    handleRemove,
    handleAdd,
    handleImport,
    handleConfigChange,
    getActions,
    settingRefs,
    importType,
    selectDtoRef,
  };
};

export const useValidate = (list: Ref<LocalDtoProps[]>) => {
  const validators: Validator = {
    name: (prop: LocalDtoProps) => {
      // name 不能为空 | 不能含有关键字 ｜ 必须英文字母开头 ｜ 不能和同级名称重复
      const { name } = prop;
      if (name === '') {
        return '名称不能为空';
      }
      if (isKeyword(name)) {
        return '名称不能是关键字';
      }
      if (!/^[A-Za-z]+(\w+)?$/.test(name)) {
        return '名称以字母开头';
      }
      const parent = prop._parent;
      const siblings = parent?.children?.filter((e) => e._id !== prop._id);

      if (parent && siblings && parent.type === 'Object' && siblings?.some((e) => e.name === prop.name)) {
        return '名称重复';
      }
    },
    example: (prop: LocalDtoProps) => {
      // 复杂类型不需要校验
      if (isComplex(prop)) {
        return;
      }
      // 属性值不能为空，长度已在input限制
      if (prop.example === '') {
        return '示例值不能为空';
      }
    },
  };
  const validateProp = (prop: LocalDtoProps, field: ValidateKeys) => {
    const localProp = [...traversalDtoProps<LocalDtoProps>(list.value)].find((e) => e._id === prop._id);
    if (!localProp) return;
    const validator = validators[field];
    const error = validator(prop);
    localProp._error[field] = error; //
    if (error) {
      return { field, error };
    }
  };

  /**
   * 校验整个 dto
   * 校验字段：name example（只有基本类型）
   */
  const validate = (): boolean => {
    if (list.value.length === 0) {
      ElMessage.error('至少添加一条属性');
      return false;
    }
    const errors = [...traversalDtoProps<LocalDtoProps>(list.value)].map((item) =>
      (Object.keys(validators) as Array<keyof typeof validators>).map((key) => validateProp(item, key)),
    );
    return errors.flat().filter(Boolean).length === 0; // 校验通过
  };
  return {
    validateProp,
    validate,
  };
};
