/**
 * 生成唯一ID
 * @returns
 */
export const genId = (): string => (1 + Math.random() * 4294967295).toString(16);

/**
 *
 */
export const camelCaseToUnderScope = (str: string): string => str.replace(/([A-Z])/g, (g) => `_${g.toLowerCase()}`);

export const underScopeToCamelCase = (str: string): string => str.replace(/_[a-z]/g, (g) => g.slice(1).toUpperCase());

// to CamelCase
export const underScopeToUpperCamelCase = (str: string) =>
  str.replace(/_([a-z])/g, (m, g) => g.toUpperCase()).replace(/^[a-z]/, (m) => m.toUpperCase());
