import { ComputedRef, InjectionKey, ref } from 'vue';

export enum SERVICE_APPEARANCE {
  NATIVE = 1,
  PROXY,
}

export interface ServiceInfo {
  id: number;
  updateTime: string;
  createTime: string;
  createUser: null;
  updateUser: null;
  isDelete: boolean;
  version: number;
  projectId: number;
  name: string;
  url: string;
  serverPort: number;
  deployId: null;
  moduleDependencyId: null;
  deposit: string;
  status: number;
  description: string;
  detail: null;
  isAllByExtend: null;
  extendVersion: null;
  classification: string;
  tag: string;
  initTimes: number;
  codeQuality: string;
  serviceRank: string;
  serviceApiUrl: string;
  druidUrl: string;
  startTime: null;
  cloneBy: string;
  repositoryId: null;
  lastSyncTime: string;
  serviceVersion: string;
  serviceVersionType: number;
  serviceSource: number;
  appearance: number;
  resourceLimit: string;
  owners: any[];
  dependencies: any[];
  sshHost: string;
}

export const isProxyService: InjectionKey<ComputedRef<boolean>> = Symbol('is-proxy-service');

export const curServiceInfo: InjectionKey<ComputedRef<ServiceInfo>> = Symbol('cur-service-info');

export const useAddProxy = () => {
  const appearance = ref<SERVICE_APPEARANCE>(1);
  return {
    appearance,
  };
};
