
import { defineComponent, reactive } from 'vue';
import Base from './Base';
const { handleSubmit, ...baseApi } = Base();
export default defineComponent({
  name: 'BooleanSettingDialog',
  setup(props, { emit }) {
    const formRules = reactive({
      defaultValue: [],
      minlength: [],
      maxlength: [],
      pattern: [],
    });
    return {
      formRules,
      ...baseApi,
      handleSubmit: () => {
        handleSubmit(emit);
      },
    };
  },
});
