
import { ref } from '@vue/reactivity';
import { computed, defineComponent, PropType, provide, watch } from '@vue/runtime-core';
import { ElForm } from 'element-plus';
import { dtoUniqueId, validateName } from './dto-list';
import PropertiesList from './PropsList.vue';
import SelectDto from './SelectDto.vue';
import { DtoModel, CreatDtoModel } from './types';
export default defineComponent({
  name: 'EditDtoModel',
  props: {
    dtoData: {
      type: Object as PropType<DtoModel | CreatDtoModel>,
      default: () => ({}),
    },
  },

  components: {
    PropertiesList,
    SelectDto,
  },

  emits: ['selectDto'],

  setup(props) {
    const dtoSelector = ref<InstanceType<typeof SelectDto>>();

    const localDtoData = ref({ ...props.dtoData });

    const dtoForm = ref<InstanceType<typeof ElForm>>();
    const rules = {
      name: [
        { required: true, message: '请输入 DTO 名称', trigger: 'blur' },
        { pattern: /^[a-zA-Z][a-zA-Z-0-9]+$/, message: '请输入英文字符', trigger: 'blur' },
        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
        { validator: validateName, trigger: 'blur', message: '禁止使用关键字' },
      ],
      zhName: [
        { required: true, message: '请输入中文名称', trigger: 'blur' },
        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
      ],
    };
    watch(
      () => props.dtoData,
      () => {
        localDtoData.value = { ...props.dtoData };
      },
    );

    const propertiesListRef = ref<InstanceType<typeof PropertiesList>>();

    const onConfirm = (selectedDto: DtoModel) => {
      if (!localDtoData.value.name) {
        localDtoData.value.name = selectedDto.name; // fix bug: #93482685
      }

      localDtoData.value.list = [...localDtoData.value.list, ...selectedDto.list];
      // eslint-disable-next-line no-unused-expressions
      dtoSelector.value?.closeDialog();
    };

    const getData = async () => {
      const validRes = await dtoForm.value?.validate();
      if (validRes) {
        const properties = propertiesListRef.value?.getData();
        if (properties) {
          localDtoData.value.list = properties;
          return localDtoData.value;
        }
        throw new Error('properties 校验失败');
      }
    };

    const openDtoSelector = () => {
      // eslint-disable-next-line no-unused-expressions
      dtoSelector.value?.openDialog();
    };

    const dtoId = computed(() => ('uniqueId' in props.dtoData ? props.dtoData.uniqueId : undefined));

    provide(dtoUniqueId, dtoId);

    return {
      dtoSelector,
      localDtoData,
      propertiesListRef,
      rules,
      dtoForm,
      dtoId,
      onConfirm,
      getData,
      openDtoSelector,
    };
  },
});
