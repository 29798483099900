import { dtoModelAPI } from '@/api/servers';
import { ElMessage } from 'element-plus';
import { computed, ComputedRef, InjectionKey, ref } from 'vue';
import { RuleItem } from 'async-validator';
import { isKeyword } from '@/utils/keyword';
import {
  CreatDtoModel,
  CreatOrUpdateDtoModel,
  DialogOptions,
  DtoApiParams,
  DtoModel,
  DtoProps,
  DtoPropsOfSelector,
  DtoType,
  EditMode,
} from './types';
import { cloneDeep } from 'lodash';
import { genId } from '@/utils/util';
export const EMPTY_DTO: CreatDtoModel = {
  name: '',
  list: [],
  zhName: '',
  serviceId: 0,
  apiId: 0,
  serviceName: '',
  dtoType: DtoType.SharedDTO,
};

export const useDialog = (options?: DialogOptions) => {
  const showDialog = ref<boolean>(false);
  const openDialog = () => {
    showDialog.value = true;
    // eslint-disable-next-line no-unused-expressions
    options?.onOpen?.();
  };

  const closeDialog = () => {
    showDialog.value = false;
    // eslint-disable-next-line no-unused-expressions
    options?.onClose?.();
  };
  return { openDialog, closeDialog, showDialog };
};

export const useEditDtoDialog = () => {
  const editMode = ref<EditMode>();

  const currentDto = ref<CreatOrUpdateDtoModel>(EMPTY_DTO); // update or create current DtoModel

  const { openDialog, closeDialog, showDialog } = useDialog(); // diaglog controller

  const setCurrentMode = (mode: EditMode) => {
    editMode.value = mode;
  };

  const setModelData = (data: CreatOrUpdateDtoModel) => {
    currentDto.value = data;
  };

  const initEdit = (mode: EditMode, data: CreatOrUpdateDtoModel) => {
    setCurrentMode(mode);
    openDialog();
    setModelData(data);
  };

  const syncDtoData = () => {
    if (editMode.value === EditMode.Create) {
      return dtoModelAPI.create(currentDto.value);
    }
    return dtoModelAPI.update(currentDto.value as DtoModel);
  };
  const dialogTitle = computed(() => (editMode.value === EditMode.Create ? '新建' : '编辑'));
  return {
    currentDto,
    showDialog,
    dialogTitle,
    initEdit,
    setDtoModel: setModelData,
    openDialog,
    closeDialog,
    syncDtoData,
  };
};
// ----------------------------------------

const dtoList = ref<DtoModel[]>();

export const useDtoList = () => {
  const loading = ref<boolean>(false);

  const setDtoList = (data: DtoModel[]) => {
    dtoList.value = data;
  };
  const fetchDtoList = async (serviceId: string) => {
    loading.value = true;
    try {
      const { data, code } = await dtoModelAPI.findAll(serviceId);
      loading.value = false;

      if (code === 0) {
        setDtoList(data);
      }
    } catch (error) {
      loading.value = false;
      console.log(error);
    }
  };

  const removeDto = async (params: DtoApiParams) => {
    loading.value = true;
    const { serviceId } = params;
    try {
      const { code } = await dtoModelAPI.remove(params);
      loading.value = false;

      if (code === 0) {
        // updatelist
        ElMessage.success('删除成功');
        fetchDtoList(serviceId);
      }
    } catch (error) {
      loading.value = false;
      console.log(error);
    }
  };
  return {
    fetchDtoList,
    setDtoList,
    loading: computed(() => loading.value),
    dtoList: computed(() => dtoList.value),
    removeDto,
  };
};

export const dtoUniqueId: InjectionKey<ComputedRef<string>> = Symbol('dtoid');

export const validateName: RuleItem['validator'] = (rule, value) => !isKeyword(value);

export const getTreeList = (list: DtoProps[]) => {
  function getNode(propData: DtoProps) {
    if (propData.children) {
      // eslint-disable-next-line no-param-reassign
      propData.children = getTreeList(propData.children);
    }
    const res: DtoPropsOfSelector = {
      ...propData,
      _id: genId(),
    };
    return res;
  }
  return cloneDeep(list).map(getNode);
};

export const filterTreeList = (list: DtoProps[], callback: Function) => {
  function getNodes(res: DtoProps[], treeItem: DtoProps) {
    if (callback(treeItem)) {
      res.push(treeItem);
    }
    if (treeItem.children && treeItem.children.length) {
      const nodes = treeItem.children.reduce(getNodes, []);
      if (nodes.length) {
        res.push({ ...treeItem, children: nodes });
      }
    }

    return res;
  }
  return list.reduce(getNodes, []);
};

export const toDtoList = (list: DtoPropsOfSelector[]) => {
  function getNode(propData: DtoPropsOfSelector) {
    if (propData.children) {
      // eslint-disable-next-line no-param-reassign
      propData.children = getTreeList(propData.children);
    }
    const { _id: id, ...rest } = propData;
    const res: DtoProps = {
      ...rest,
    };
    return res;
  }
  return cloneDeep(list).map(getNode);
};

export const getMaxDepth = (list: DtoProps[]) => {
  const getDepth = (prop: DtoProps) => {
    let depth = 0;
    if (prop.children) {
      depth = getMaxDepth(prop.children);
    }
    return depth + 1;
  };

  return Math.max(...list.map(getDepth));
};

export const getSubTreeByDepth = (tree: DtoProps, maxDepth: number) => {
  const walkAndCopy = (tree: DtoProps, depth = 1) => {
    if (depth <= maxDepth) {
      const copy = { ...tree };
      if (tree.children) {
        copy.children = [];
        tree.children.forEach((node: DtoProps) => {
          const subTree = walkAndCopy(node, node.name === 'item' ? depth : depth + 1); // 根据需求：层数统计不需要统计数组 item
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          subTree && copy.children!.push(subTree);
        });
      }
      return copy;
    }
  };
  return walkAndCopy(tree);
};
