
import { ref } from '@vue/reactivity';
import { defineComponent } from '@vue/runtime-core';
import DtoList from './DtoList.vue';
export default defineComponent({
  name: 'DtoListDialog',
  components: {
    DtoList,
  },
  emits: ['on-confirm'],
  setup(props, ctx) {
    const dtoListRef = ref<InstanceType<typeof DtoList>>();

    const showDtoList = ref<boolean>(false);
    const openDtoList = () => {
      showDtoList.value = true;
    };

    const closeDtoList = () => {
      showDtoList.value = false;
      // eslint-disable-next-line no-unused-expressions
      dtoListRef.value?.resetList();
    };

    const onConfirmSelect = () => {
      const selected = dtoListRef.value?.getSelectedData();
      ctx.emit('on-confirm', selected);
      closeDtoList();
    };

    return {
      showDtoList,
      dtoListRef,
      openDtoList,
      closeDtoList,
      onConfirmSelect,
      openDialog: openDtoList, // 对外统一接口
      closeDialog: closeDtoList,
    };
  },
});
