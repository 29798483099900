<template>
  <div class="detail-with-tabs">
    <span class="method-type">{{ apiInfo?.methodType }}</span>
    <el-tabs v-model="activeTab" v-loading="loading">
      <el-tab-pane label="请求参数" name="req">
        <div class="tab-content-wrap">
          <params-list :apiInfo="apiInfo" :serviceInfo="serviceInfo" v-if="apiInfo" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="返回参数" name="res">
        <div class="tab-content-wrap">
          <params-list :is-response="true" :apiInfo="apiInfo" :serviceInfo="serviceInfo" v-if="apiInfo" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import ParamsList from './List.vue';
import { findServiceApi, getServiceById } from '@/api/servers/index';
import { useRoute } from 'vue-router';
export default defineComponent({
  components: {
    ParamsList,
  },
  setup() {
    const activeTab = ref('req');
    const loading = ref(false);
    const apiInfo = ref(null);
    const serviceInfo = ref(null);
    const route = useRoute();
    const serviceId = ref(route.params.serviceId);
    const apiId = ref(route.params.apiId);
    const getInfo = async () => {
      loading.value = true;
      try {
        const [resServiceInfo, resApiInfo] = await Promise.all([
          getServiceById({
            id: serviceId.value,
          }),
          findServiceApi({
            serviceId: serviceId.value,
            uniqueId: apiId.value,
          }),
        ]);
        serviceInfo.value = resServiceInfo.data;
        apiInfo.value = resApiInfo.data;
      } catch (e) {}
      loading.value = false;
    };

    getInfo();

    return {
      activeTab,
      loading,
      apiInfo,
      serviceInfo,
      serviceId,
      apiId,
    };
  },
});
</script>
<style lang="scss" scoped>
.tab-content-wrap {
  min-height: 400px;
}
.detail-with-tabs {
  .method-type {
    position: relative;
    padding: 2px 8px;
    z-index: 11;
    background-color: #f0f0f0;
    top: -32px;
    left: 140px;
  }
}
</style>
